header  {
  padding: 20px 0px;
  .menu-sand {
    display: none;
  }
  .menu {
    a {
      color: #8ea499;
    }
  }
  .logo {
    img {
      width: 380px;
      height: auto;
    }
  }
  .instagram {
    &:hover {
      svg {
        path {
          fill: #52BCA6;
        }
      }
    }
    svg {
      path {
        transition:  all ease-in-out 300ms;
        fill: #607664;
      }
    }
  }
}

@media(max-width: 1024px) {
  html {
    max-width: 100vw;
    overflow-x: hidden;
  }
  body {
    margin-top: 114px;
  }
  header {
    box-shadow: 0px 0px 6px #00000029;
    position: fixed;
    z-index: 10;
    top: 0px;
    width: 100vw;
    background-color: white;
    .menu-sand {
      display: block;
      path {
        fill: #607664;
      }
    }

    .menu-mobile {
      position: fixed;
      top: 90px;
      right: -100%;
      width: 100%;
      height: calc(100% - 90px);
      background-color:white;
      z-index: 9;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: flex-start !important;
      transition: all ease-in-out 300ms;
      &.act {
        right: 0px
      }
    }
  }
}

@media (max-width: 550px)  {
  header .logo img {
    width: 230px;
  }

  .c-top h1 {
    width: 100%;
    font-size: 40px;
    line-height: normal;
    padding: 15px;
  }
}