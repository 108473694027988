.c-top {
  h1 {
    margin: 0px 0px 0px 0px;
    padding: 15px 30px;
    background-color:$primary-color-2;
    font-size: 50px;
    font-weight: 100;
    color:white;
    text-transform: uppercase;
    width: calc(100% + 30px);
    position: relative;
    z-index: 1;
  }

  .flex .col img {
    max-width: 100%;
    height: auto;
  }


}

.img-filter {
  position: relative;
  &:before {
    right: 10%;
    position: absolute;
    top: -10px;
    height: 20px;
    width: 30%;
    content:"";
    background-color: $secondary-color;
    display: block;
  }
}

img {
  display: block;
}

.effect-3 {
  border-bottom: 10px solid $primary-color-2;
}