$primary-color:#607664;
$primary-color-2:#8EA499;
$secondary-color:#52BCA6;
$third-color:#C2A873;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&family=Ubuntu:wght@300;400;500;700&display=swap');
@import "reset";
@import "components/header";
@import "components/c-top";


body {
  background-image: url("../images/bk.svg");
  background-position-x: 10vw;
  background-position-y: 20px;
  background-repeat: no-repeat;
  background-size: 30vh;
}
.container {
  max-width:1140px;
  margin: 0 auto;
}

.flex {
  display: flex;
  > .col {
    flex: 1;
  }
}

.title-effect {
  background-color: $secondary-color;
  color:white;
  font-size: 28px;
  display: inline-block;
  text-transform: uppercase;
  padding: 8px 12px;
}

.effect-2 {
  position: relative;
  img {
    position: relative;
    z-index: 2;
  }
  &:before {
    left: -20px;
    position: absolute;
    bottom: -10px;
    height: 40px;
    width: 80%;
    content:"";
    background-color: $secondary-color;
    display: block;
  }
}

.third-title {
  background-color: $primary-color-2;
}

.h-personal {
  aspect-ratio: 8 / 9;
}

.context > * + * {
  margin-top: 20px;
}

.third-effect {
  &:before {
    background-color: $primary-color-2;
  }
}

.whatsapp {
  z-index: 99;
  position: fixed;
  bottom: 30px;
  right: 30px;
  img {
    max-width: 64px;
    height: auto;
  }
}

@media (max-width: 768px) {
  body {
    max-width: 100vw;
    overflow-x: hidden;
  }
}


footer {
  .aspect-radio {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
    margin-top: 26px;
  }
  margin-top: 60px;
  h2 {
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
  }
  background-color: #e8e8e8;
}